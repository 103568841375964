import { Suspense, lazy, ElementType } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
// export const RegisterPage = Loadable(lazy(() => import('../pages/auth/RegisterPage')));
export const VerifyCodePage = Loadable(lazy(() => import('../pages/auth/VerifyCodePage')));
export const NewPasswordPage = Loadable(lazy(() => import('../pages/auth/NewPasswordPage')));
export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/ResetPasswordPage')));

// DASHBOARD: GENERAL
export const GeneralAppPage = Loadable(lazy(() => import('../pages/dashboard/GeneralAppPage')));

export const GeneralAnalyticsPage = Loadable(
  lazy(() => import('../pages/dashboard/GeneralAnalyticsPage'))
);

// DASHBOARD: REPORT
export const ReportListPage = Loadable(lazy(() => import('../pages/dashboard/ReportListPage')));
export const ReportDetailsPage = Loadable(
  lazy(() => import('../pages/dashboard/ReportDetailsPage'))
);
export const ReportCreatePage = Loadable(lazy(() => import('../pages/dashboard/ReportCreatePage')));
export const ReportEditPage = Loadable(lazy(() => import('../pages/dashboard/ReportEditPage')));

// DASHBOARD: INVOICE
export const InvoiceListPage = Loadable(lazy(() => import('../pages/dashboard/InvoiceListPage')));
export const InvoiceDetailsPage = Loadable(
  lazy(() => import('../pages/dashboard/InvoiceDetailsPage'))
);
export const InvoiceCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/InvoiceCreatePage'))
);
export const InvoiceEditPage = Loadable(lazy(() => import('../pages/dashboard/InvoiceEditPage')));

// DASHBOARD: ORDER
export const OrderListPage = Loadable(lazy(() => import('../pages/dashboard/OrderListPage')));
export const OrderDetailsPage = Loadable(lazy(() => import('../pages/dashboard/OrderDetailPage')));

// DASHBOARD: SIMULATION
export const SimulationListPage = Loadable(
  lazy(() => import('../pages/dashboard/SimulationListPage'))
);
export const SimulationDetailPage = Loadable(
  lazy(() => import('../pages/dashboard/SimulationDetailPage'))
);

// DASHBOARD: USER
export const UserProfilePage = Loadable(lazy(() => import('../pages/dashboard/UserProfilePage')));
export const UserCardsPage = Loadable(lazy(() => import('../pages/dashboard/UserCardsPage')));
export const UserListPage = Loadable(lazy(() => import('../pages/dashboard/UserListPage')));
export const UserAccountPage = Loadable(lazy(() => import('../pages/dashboard/UserAccountPage')));
export const UserCreatePage = Loadable(lazy(() => import('../pages/dashboard/UserCreatePage')));
export const UserEditPage = Loadable(lazy(() => import('../pages/dashboard/UserEditPage')));

export const KanbanPage = Loadable(lazy(() => import('../pages/dashboard/KanbanPage')));

export const ReviewPage = Loadable(lazy(() => import('../pages/dashboard/ReviewPage')));

// TEST RENDER PAGE BY ROLE
export const PermissionDeniedPage = Loadable(
  lazy(() => import('../pages/dashboard/PermissionDeniedPage'))
);

// MAIN
export const Page500 = Loadable(lazy(() => import('../pages/Page500')));
export const Page403 = Loadable(lazy(() => import('../pages/Page403')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
export const MaintenancePage = Loadable(lazy(() => import('../pages/MaintenancePage')));
