import { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import LoadingScreen from '../components/loading-screen';
import Login from '../pages/auth/LoginPage';

type AuthGuardProps = {
  children: React.ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { pathname } = useLocation();
  
  const [isInitialized, setIsInitialized] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  useEffect(() => {
    const checkAuthState = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        setIsAuthenticated(true);
      } catch (error) {
        setIsAuthenticated(false);
      }
      setIsInitialized(true);
    };
    checkAuthState();
  }, []);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <> {children} </>;
}
