import { createSlice } from '@reduxjs/toolkit';
// utils
import { IUserAccount } from 'src/@types/user';

// ----------------------------------------------------------------------

const initialState: IUserAccount = {
  id: '',
  name: '',
  email: '',
  phoneNumber: '',
  company: '',
  isVerified: false,
  role: '',
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setName(state, action) {
      state.name = action.payload;
    },
    setId(state, action) {
      state.id = action.payload;
    },
    setEmail(state, action) {
      state.email = action.payload;
    },
    setCompany(state, action) {
      state.company = action.payload;
    },
    setIsVerified(state, action) {
      state.isVerified = action.payload;
    },
    setRole(state, action) {
      state.role = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setName, setId, setEmail, setCompany, setIsVerified, setRole } = slice.actions;
